<template>
  <div class="landing">
    <hero :observer="observer" id="meaningful_conversations"/>
    <right-time :observer="observer" id="now_is_the_time"/>
    <how-it-works :observer="observer" id="how_it_works"/>
    <how-it-helps :observer="observer" id="how_it_helps"/>
    <pay-for-time :observer="observer" id="pay_for_time"/>
    <small-talk :observer="observer" id="faq"/>
    <!-- <faq /> -->
    <help-us />
  </div>
</template>

<script>
import Hero       from './components/Hero'
import RightTime  from './components/RightTime'
import HowItWorks from './components/HowItWorks'
import HowItHelps from './components/HowItHelps'
import PayForTime from '@/views/Landing/components/PayForTime'
import SmallTalk  from '@/views/Landing/components/SmallTalk'
import Faq        from '@/views/Landing/components/Faq'
import HelpUs     from '@/views/Landing/components/HelpUs'

export default {
  name: 'landing',
  components: { HelpUs, Faq, SmallTalk, PayForTime, HowItHelps, HowItWorks, RightTime, Hero },
  data() {
    return {observer: null,}
  },
  created() {
    document.title = "Swifttalk";
    this.observer = new IntersectionObserver(
      this.onElementObserved, 
      {
        root: this.$el,
        rootMargin: '20px',
        threshold: 0.2
      }
    );

    var script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-R31G7M6QHX";
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-R31G7M6QHX');

  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting}) => {
        if (!isIntersecting) {
          return;
        }
        this.$store.commit('changeSection', {section: target.id});
      });
    }
  },
}
</script>

<style lang="scss">
body {
  background-color: #FFEDEE;
  background-image: url('../../assets/landing-hero-bg.svg');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: min(1200px, 65%) auto;

  @include respond-below(sm) {
    background-image: none;
  }
}
</style>
<style lang="scss" scoped>
.landing {
  // just in case
}
</style>
