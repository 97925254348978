<template>
  <div class="how-it-works">
    <div class="how-it-works__block">
      <div class="how-it-works__art">
        <img alt="" class="how-it-works__art-img" src="@/assets/landing-how-it-works-main.png" />
      </div>
      <div class="how-it-works__text">
        <h3 class="how-it-works__text-title">How it works</h3>
        <p class="how-it-works__text-p">
          Swifttalk is the new approach to collaboration where the user and their privacy are placed
          at the heart. No more scheduling conversation time. Interact only when you and your
          colleagues are ready. Want to chat? Just press the ‘Ready for Coffee’ button. Jump into conversation instantaneously
          without any pre-arrangements or scheduling when your colleagues are in the same status. Interact only when you and
          your
          teammates or favorite contacts are ready.
        </p>
        <img alt="" class="how-it-works__text-icons" src="@/assets/landing-how-it-works-icons.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'how-it-works',
  props: ["observer"],
  mounted() {
    this.observer.observe(this.$el);
  }
}
</script>

<style lang="scss" scoped>
.how-it-works {
  background-color: #FDF2FA;
  background-image: url('../../../assets/landing-how-it-works-bg-dots.svg');
  background-repeat: no-repeat;
  background-position: calc(50% + 600px) 66%;
  background-size: 176px;
  padding-bottom: 64px;
  &__block {
    @include container();

    display: grid;
    grid-template-columns: minmax(0, 1.4fr) minmax(0, 1fr);
    grid-gap: 32px;
  }

  &__text {
    &-title {
      font-size: 56px;
      font-weight: bold;
      line-height: 64px;
      margin: 64px 0 32px 0
    }
    &-p {
      font-size: 21px;
      line-height: 1.4em;
      color: #888;
    }
    &-icons {
      max-width: 100%;
      margin-top: 42px;
      padding: 21px;
      border-radius: 18px;
      background: white;
      box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.08);
    }
  }

  &__art {
    margin-top: 64px;

    &-img {
      width: calc(100% + 124px);
      margin-right: -100px;
      margin-left: -24px;
    }
  }

  @include respond-below(sm) {
    padding-bottom: 0px;
    &__block {
      display: flex;
      flex-direction: column-reverse;
    }

    &__text {
      &-title {
        font-size: 36px;
        font-weight: bold;
        line-height: 64px;
        margin: 24px 0 0 0;
        text-align: center;
      }
      &-p {
        font-size: 21px;
        font-weight: 400;
        line-height: 32px;
      }
      &-icons {
        padding: 21px;
        position: unset;
        display: block;
        width: auto;
        max-width: 100%;
        margin: 0 auto;
        margin-top: 42px;
      }
    }
    &__art {
      margin-top: 0;
      padding-bottom: 32px;
      &-img {
        max-width: calc(100vw - (var(--ui-container--padding) * 2));
        margin: 0;
      }
    }
  }
}
</style>
