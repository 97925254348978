<template>
  <div class="faq">
    <div class="faq__container">
      <h1 v-if="$_isMobile" class="faq__title">
        F.A.Q.
      </h1>
      <h1 v-else class="faq__title">
        Frequently Asked Questions
      </h1>
      <div class="faq__item">
        <img class="faq__item-chevron-icon" src="@/assets/icon-chevron.svg"/>
        <h3>How many colleagues can I add?</h3>
      </div>
      <div class="faq__panel">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus accumsan a augue nec mollis. In auctor sed eros quis
        sagittis. Sed molestie porttitor nulla commodo tincidunt. In vitae orci nisi. Vestibulum mi est, pulvinar ac rhoncus
        non, posuere in nisi. Fusce suscipit Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus accumsan a augue
        nec mollis. In auctor sed eros quis sagittis. Sed molestie porttitor nulla commodo tincidunt. In vitae orci nisi.
        Vestibulum mi est, pulvinar ac rhoncus non, posuere in nisi. Fusce suscipit</p>
      </div>
    </div>
    <div class="faq__placeholder">
    </div>
  </div>
</template>

<script>

import responsiveMixin from '../../../cssBreakpointsMixin';

export default {
  name: 'faq',
  mixins: [responsiveMixin],
  mounted() {
    var acc = document.getElementsByClassName("faq__item");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.faq {
  padding-bottom: 42px;
  color: #000000;
  background-color: #ffffff;

  &__title {
    font-size: 56px;
    font-weight: bold;
    line-height: 64px;
    margin: 0;
    padding: 64px 0 32px 0;
  }

  &__container {
    @include container();
    padding-bottom: 42px;
  }
  &__item {
    display: flex;
    margin-bottom: 0px;
      
    &-chevron-icon {
        transition: transform 0.1s ease-in-out;
        padding-right: 16px;
        padding-left: 0px;
    }
      
    h3 {
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      color: #333;
    }

    &:hover {
      cursor: pointer;
    }

    &.active {
        .faq__item-chevron-icon {
            -o-transform:rotate(180deg);
            -ms-transform:rotate(180deg);
            -moz-transform:rotate(180deg);
            -webkit-transform:rotate(180deg);
            transform:rotate(180deg);
            padding-right: 0px;
            padding-left: 16px;
        }
    }
  }

  &__panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;

    p {
      margin: 0px;
      font-size: 16px;
      line-height: 32px;
      color: #777;
      width: 70%;
    }
  }
  
  @include respond-below(sm) {
    padding: 0;

    &__title {
      font-size: 32px;
      line-height: 1.2;
      text-align: center;
      padding: 32px 0 16px 0;
    }

    &__panel {

    p {
      width: 100%;
    }
  }
  }
}
</style>
