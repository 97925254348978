<template>
    <img alt="" class="small-talk__item-quote" src="@/assets/landing-quote.svg" />

    <p v-html="text"/>
    <div v-if="author" class="small-talk__item-author-wrapper" v-html="author"/>
    <div v-else class="small-talk__item-author-wrapper">
        <reviewer/>
    </div>
</template>

<script>
import Reviewer from "./Reviewer";

export default {
    name: "SmallTalkItem",
    components: {Reviewer},
    props: {
        text: {
            type: String,
            required: true
        },
        author: {
            type: String, 
            default: null
        }
    }
}
</script>

<style lang="scss" scoped>
.small-talk__item-author-wrapper {
    :deep(h4) {
        font-size: 24px;
        font-weight: bold;
        color: black;
    }

    :deep(.small-talk__item-reviewer) {
        display: flex;

        .small-talk__item-reviewer-img {
            width: 64px;
            height: 64px;
            margin: 16px 8px 16px 0px;
            // margin-right: 8px;
            // margin-bottom: 36px;
        }

        .small-talk__item-reviewer-name {
            font-size: 18px;
            line-height: 1;
            margin: 0;
            color: black;
            small {
                font-size: 12px;
                display: block;
                white-space: nowrap;
                color: grey;
            }
        }
    }
}
</style>