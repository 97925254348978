<template>
  <div class="app-footer">
    <a class="app-footer__logo" href="#">SwiftTalk</a>

    <small class="app-footer__copy">Copyright SwiftTalk All rights reserved {{ new Date().getFullYear() }}</small>

    <div class="app-footer__social">
      <a class="app-footer__social-link" href="mailto:hello@swifttalk.me">
        <img alt="" class="class" src="@/assets/icon-mail.svg" />
      </a>
      <a class="app-footer__social-link" href="https://www.linkedin.com/company/swifttalk/">
        <img alt="" class="class" src="@/assets/icon-li.svg" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.app-footer {
  @include container();

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
  margin-bottom: 28px;

  &__logo {
    font-size: 28px;
    font-weight: 700;
    display: block;
    margin-right: 32px;
    text-decoration: none;
    color: #6F6C99;
  }
  &__copy {
    font-size: 15px;
    display: block;
    text-align: center;
    color: #7D79A3;
  }

  &__social {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;
    &-link {
      display: block;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__demo-btn {
    @include ui-button();
    margin-left: auto;
    --btn-bg-color: #D961E5;

    --btn-text-color: #FFD8D8;
  }

  @include respond-below(sm) {
    flex-direction: column-reverse;

    &__logo {
      display: none;
    }

    &__copy {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
}
</style>
