<template>
  <app-header />
  <router-view />
  <app-footer />
</template>

<script>
import AppHeader from './components/AppHeader'
import AppFooter from '@/components/AppFooter'

export default {
  name: 'app',
  components: { AppFooter, AppHeader },
}
</script>
