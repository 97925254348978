import { createRouter, createWebHistory } from 'vue-router'
import Landing                            from '../views/Landing'

const routes = [
  {
    path: '/',
    name: 'landing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Landing.vue')
    component: Landing, // but let's load in normally for now
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
