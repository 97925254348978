<template>
  <div class="hero">
    <div class="hero__art">
      <img alt="" class="hero__art-img" src="@/assets/landing-hero-img.svg" />
    </div>

    <div class="hero__message">
      <p class="hero__message-line _main">
        Swift<b>talk</b>
      </p>

      <p class="hero__message-line _middle">
        meaningful conversations
      </p>

      <p class="hero__message-line _last">
        <b>at work</b>
      </p>
    </div>
    <div class="hero__form">
      <form action="https://swifttalk.us6.list-manage.com/subscribe/post?u=5192d4196cc29b230330212a8&amp;id=732c843346" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
      <div id="mc_embed_signup_scroll">
        <div class="mc-field-group hero__form-email">
          <label for="mce-EMAIL">Join our newsletter </label>
          <input type="email" value="" name="EMAIL" id="mce-EMAIL" placeholder="Enter company email">
        </div>
        <div id="mce-responses" class="clear">
          <div class="response" id="mce-error-response" style="display:none"></div>
          <div class="response" id="mce-success-response" style="display:none"></div>
        </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_5192d4196cc29b230330212a8_732c843346" tabindex="-1" value=""></div>
        <div class="clear"><input type="submit" value="Join" name="subscribe" id="mc-embedded-subscribe" class="hero__form-btn"></div>
      </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hero',
  props: ["observer"],
  mounted() {
    this.observer.observe(this.$el);
  }
}
</script>

<style lang="scss" scoped>
.hero {
  @include container();

  display: grid;
  margin-bottom: 180px;
  grid-template: "message image" "form image";
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, auto);

  &__message {
    margin: 34px 0 5px 0;
    grid-area: message;

    &-line {
      line-height: 1.1;
      display: block;
      margin: 0;
      white-space: nowrap;

      &._main {
        font-size: 64px;
        font-weight: 700;
        color: #7D79A2;
        & b {
          color: #D961E5;
        }
      }

      &._middle {
        font-size: 58px;
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 1.5;
        color: #000000;
      }

      &._last {
        font-size: 48px;
        font-weight: 400;
        color: #000000;

        & b {
          font-weight: 400;
          color: #7D79A2;
        }
      }
    }
  }

  &__form {
    grid-area: form;

    label {
      font-size: 16px;
      display: block;
      margin: 24px 0;
      color: #7D79A2;

      span {
        display: block;
        margin: 1rem 0;
      }
    }

    &-email {
      @include ui-text();

      input {
        max-width: 100%;
        padding: 0 3em;
        height: 60px;
        left: 0px;
        top: 0px;
        display: block;
        border-radius: 15px;
      }

    }

    &-check {
      @include ui-check();
      --ui-check-active-color: #6082F1;
    }

    &-btn {
      @include ui-button();
      padding: 16px 24px;
      width: 172px;
      height: 50px;
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      border-radius: 15px;
      --btn-bg-color: #6082F1;
      cursor: pointer;
      margin-top: 30px;
    }
  }

  &__art {
    position: relative;
    z-index: -1;
    grid-area: image;
    // margin-right: -10px;

    &-img {
      position: absolute;
      top: 70px;
      left: -80px;
      width: calc(100% + 150px);
    }
  }

  @include respond-below(sm) {
    margin-bottom: 40px;
    grid-template: "message" "form" "image";
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);

    &__message {
      grid-area: message;

      &-line {
        line-height: 1.4;
        display: block;

        &._main {
          font-size: 32px;
        }

        &._middle {
          font-size: 28px;
        }

        &._last {
          font-size: 24px;
        }
      }
    }

    &__form {
      label {
        margin: 20px 0px 15px;
        font-size: 12px;
      }

      &-email {
        input {
          height: 44px;
          width: 90vw;
        }
      }

      &-btn {
        margin-top: 20px;
        padding: 0px;
        height: 44px;
        -webkit-appearance:none;
      }
    }

    &__art {
      position: unset;
      z-index: unset;

      &-img {
        position: unset;
        display: block;
        width: auto;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
}
</style>
