<template>
  <div class="app-header" ref="header">
    
    <div class="app-header__container">
      <a class="app-header__logo" v-on:click="changeAnchor('meaningful_conversations', true)">
        <img class="app-header__logo-img" src="@/assets/logo.svg" />
      </a>
      <nav class="app-header__nav">
        <a ref="now_is_the_time" class="app-header__nav-link"  v-on:click="changeAnchor('now_is_the_time', true)">Why now</a>
        <a ref="how_it_works" class="app-header__nav-link"  v-on:click="changeAnchor('how_it_works', true)">How it works</a>
        <a ref="how_it_helps" class="app-header__nav-link"  v-on:click="changeAnchor('how_it_helps', true)">Use cases</a>
        <a ref="pay_for_time" class="app-header__nav-link"  v-on:click="changeAnchor('pay_for_time', true)">Pricing</a>
        <a ref="faq" class="app-header__nav-link"  v-on:click="openBlog()">Blog</a>
      </nav>
      <div class="app-header__buttons">
        <button class="app-header__buttons-demo-btn"  onclick="Calendly.initPopupWidget({url: 'https://calendly.com/swifttalk/30min'});return false;">Request a demo</button>
        <button class="app-header__buttons-login-btn"  v-on:click="openLogin()">Login</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'app-header',
  computed: mapState(['selected_section']),
  data() {
    return {
      isScrolling: false,
    }
  },
  watch: {
    selected_section (newSection, oldSection) {
      this.changeAnchor(newSection, false);
    }
  },
  created () {
    // calendly integration
    let js = document.createElement('script')
    js.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    js.setAttribute('type', 'text/javascript')
    document.head.appendChild(js)

    let css = document.createElement('link')
    css.setAttribute('href', 'https://assets.calendly.com/assets/external/widget.css')
    css.setAttribute('rel', 'stylesheet')
    document.head.appendChild(css)

    this._scrollHandler = (ev) => {
      if (window.scrollY >= 18) {
        if (!this.$refs.header.classList.contains('_scrolled')) {
          this.$refs.header.classList.add('_scrolled')
        }
      } else {
        if (this.$refs.header.classList.contains('_scrolled')) {
          this.$refs.header.classList.remove('_scrolled')
        }
      }
    }
    window.addEventListener('scroll', this._scrollHandler)
  },
  unmounted () {
    window.removeEventListener('scroll', this._scrollHandler)
  },
  methods: {
    openLogin() {
      window.open("https://app.swifttalk.me", "_blank");
    },
    openBlog() {
      window.open("https://blog.swifttalk.me", "_blank");
    },
    changeAnchor(id, isSelectedFromNavBar) {
      if (!this.isScrolling || isSelectedFromNavBar) {
        if (isSelectedFromNavBar){
          this.isScrolling = true;
          var _ = this 
          setTimeout(function(){
            _.isScrolling = false;
          }, 1000);
        }

        var allElements = document.getElementsByClassName('app-header__nav-link')
        for (let el of allElements) {
          el.classList.remove("_active");
        }
        if (id === "meaningful_conversations") {
          if (isSelectedFromNavBar) {
            window.scrollTo({top: 0, behavior: 'smooth'});
          }
        } else {
          var element = this.$refs[id]
          if (isSelectedFromNavBar) {
            var section = document.getElementById(id)
            section.scrollIntoView({behavior: 'smooth'});
          }
          element.classList.add("_active");
        }
      }
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.app-header {
  &__container {
    @include container();
    display: flex;
    align-items: center;
    padding-top: 10px;
    margin-top: 18px;
    padding-bottom: 10px;
    margin-bottom: 18px;
  }

  &._scrolled {
    background: #ffffffff;
  }

  z-index: 10;
  position: sticky;
  top: 0;

  &__logo {
    color: #6F6C99;
    cursor: pointer;

    &-img {
      max-width: 100%;
      height: auto;
    }
  }

  &__nav {
    margin-left: 75px;
      
    &-link {
      font-size: 16px;
      font-weight: 500;
      margin: 1em 0.69em;
      text-decoration: none;
      cursor: pointer;

      &._active {
        position: relative;

        &:before {
          position: absolute;
          top: 130%;
          left: 0;
          width: 90%;
          min-width: 30px;
          height: 2px;
          content: "";
          background: #6F6C99;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    margin-left: auto;
    &-demo-btn {
      
    @include ui-button();
      width: 172px;
      height: 50px;
      font-size: 16px;
      white-space: nowrap;
      border-radius: 15px;
      font-weight: bold;
      cursor: pointer;
      

      --btn-bg-color: #D961E5;
      --btn-text-color: #FFD8D8;
    }

    &-login-btn {
      @include ui-button();
      width: 100px;
      height: 50px;
      font-size: 16px;
      white-space: nowrap;
      border-radius: 15px;
      font-weight: bold;
      cursor: pointer;
      margin-left: 30px;

      --btn-bg-color: #6082F1;
      --btn-text-color: #FFD8D8;
    }
  }

  @include respond-below(sm) {
    &__nav {
      display: none;
    }

    &__buttons-login-btn {
      display: none;
    }
  }
    
  @include respond-below(xs) {
    .app-header__logo {
        font-size: 25px;
    }
      
    .app-header__demo-btn {
        padding: 8px 12px;
        width: auto;
    }
    .app-header__login-btn {
        padding: 8px 12px;
        width: auto;
    }
  }
}
</style>
