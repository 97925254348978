<template>
  <div class="small-talk">
    <div class="small-talk__container">
      <h1 class="small-talk__title">
        Why the small talk is important
        <button class="small-talk__arrow-btn" @click="onArrowClick">
            <img src="@/assets/icon-right-arrow.svg"/>
        </button>
      </h1>
      <div class="small-talk__container-scroll">
          <swiper
              v-if="slidesPerView"
              :slides-per-view="slidesPerView"
              :space-between="20"
              loop
              @swiper="onSwiper"
          >
              <swiper-slide v-for="review in reviews" :key="review.id" class="small-talk__item">
                  <small-talk-item :text="review.text" :author="review.author"/>
              </swiper-slide>
          </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import SmallTalkItem from "./SmallTalkItem";
import reviews from "./reviews.json";
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

// install Swiper modules
SwiperCore.use([Pagination]);

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

export default {
  name: 'small-talk',
  props: ["observer"],
  components: {SmallTalkItem, Swiper, SwiperSlide},
  data() {
      return {
          reviews: reviews,
          swiper: null,
          currentFirstSlide: 1,
          slidesPerView: undefined,
          mobileBreakPoint: 1201
      }
  },
  mounted() {
      this.observer.observe(this.$el);
      this.setSlidesPerView();
      window.addEventListener("resize", this.setSlidesPerView);
  },
  unmounted() {
      window.removeEventListener("resize", this.setSlidesPerView);
  },
  methods: {
      onArrowClick() {
          if (this.currentFirstSlide === this.reviews.length - 2) this.currentFirstSlide = 0;
          this.swiper.slideToLoop(this.currentFirstSlide);
          this.currentFirstSlide++;
      },
      onSwiper(swiper) {
          this.swiper = swiper;
      },
      setSlidesPerView() {
          this.slidesPerView = window.innerWidth < this.mobileBreakPoint ? 1 : 3;
      }
  }
}
</script>

<style lang="scss" scoped>
.small-talk {
  color: #ffffff;
  background-color: #6082F1;

  &__title {
    font-size: 56px;
    font-weight: bold;
    line-height: 64px;
    display: flex;
    margin: 0;
    padding: 64px 0 32px 0;
    text-align: center;
  }

  &__arrow-btn {
    font-size: 22px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 64px;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
    margin-left: auto;
    transition: all ease-out 180ms;
    color: #6082F1;
    border: 0 none;
    border-radius: 50%;
    outline: none;
    background: white;
    cursor: pointer;
      
    img {
        height: 25px;
        width: 25px;
        filter: invert(44%) sepia(16%) saturate(6287%) hue-rotate(212deg) brightness(106%) contrast(89%);
    }

    &:hover {
      transform: translateX(8px);
    }
  }

  &__container {
    @include container();
    padding-bottom: 64px;
      
    :deep(.swiper-container) {
        .swiper-slide {
            height: unset;
        }
    }

    &-scroll {

    }

    &-items {
      display: grid;
      margin-top: 32px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 32px;
    }
  }

  :deep(.small-talk__item) {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 32px;
    padding: 24px 48px;
    color: #888;
    border-radius: 18px;
    background: white;

    .small-talk-quote {
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }

  @include respond-below(sm) {
    padding: 0;

    &__container {
      &-scroll {
        overflow-x: auto;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;

        @include scrollbar-awesome();
        --scroll-thumb-color: white;
      }

      &-items {
        display: grid;
        width: 300%; // if you heed to add card - change this value
        margin-top: 16px;
        padding-bottom: 16px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 16px;
      }
    }

    &__item {
      scroll-snap-align: center;

      &:first-child {
        margin-left: 8px
      }
      &:last-child {
        margin-right: 8px
      }
    }

    &__title {
      font-size: 32px;
      line-height: 1.2;
      text-align: center;
    }

    &__arrow-btn {
      display: none;
      visibility: hidden;
    }
  }
}
</style>
