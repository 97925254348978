<template>
  <div class="how-it-helps">
    <div class="how-it-helps__container">
      <h1 v-if="$_isMobile" class="how-it-helps__title">
        Use cases
      </h1>
      <h1 v-else class="how-it-helps__title">
        How Swifttalk can help YOU
      </h1>
      <div class="how-it-helps__container-cols">
        <div class="how-it-helps__container-col">
          <div class="how-it-helps__block _pink">
            <h3>Enterprise</h3>
            <p>Equip your virtual teams with an effective tool for meaningful conversations, knowledge sharing and newcomers’
              integration. Provide company leadership and management with an overview of the most discussed topics across the
              company. Foster cross-team collaboration, instil company values and promote corporate culture more effectively
              compared to traditional tools.</p>
          </div>

          <div class="how-it-helps__block _blue">
            <h3>Small and Medium Business</h3>
            <p>Improve your bottom line by integrating small talk into the existing daily workflows of your virtual teams.
              Increase productivity, employees’ emotional connection to the business and
              work-life balance.</p>
          </div>
          <div class="how-it-helps__block _green">
            <h3>Networking for virtual events <span>Coming soon</span></h3>
            <p>Do you conduct virtual events such as conferences, marketing events and webinars and would like to bring your
              networking during the event
              to the next level? If so, then Swifttalk can help!</p>
          </div>
        </div>
        <div class="how-it-helps__container-cols-col">
          <div class="how-it-helps__art">
            <img alt="" class="how-it-helps__art-img" src="@/assets/landing-how-it-helps.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import responsiveMixin from '../../../cssBreakpointsMixin';

export default {
  name: 'how-it-helps',
  props: ["observer"],
  mixins: [responsiveMixin],
  mounted() {
    this.observer.observe(this.$el);
  }
}
</script>

<style lang="scss" scoped>
.how-it-helps {
  background-color: #FFFFFF;
  background-image: url('../../../assets/landing-how-it-helps-bg-dots.svg');
  background-repeat: no-repeat;
  background-position: 10% 80%;
  background-size: 176px;

  &__title {
    font-size: 56px;
    font-weight: bold;
    line-height: 64px;
    margin: 0;
    padding: 64px 0 32px 0
  }

  &__container {
    @include container();
    padding-bottom: 64px;

    &-cols {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-gap: 32px;
    }
  }

  &__block {
    box-sizing: border-box;
    margin-bottom: 32px;
    padding: 24px;
    text-align: center;
    border-width: 4px;
    border-style: solid;
    border-radius: 16px;
    background: white;
      
    &:last-child {
      margin-bottom: 0;
    }

    &._pink {
      border-color: #F8D2EC;
    }

    &._blue {
      border-color: #DAE2FF;
    }

    &._yellow {
      border-color: #FFF2DC;
    }

    &._green {
      border-color: #BAFDEE;
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 16px;
      span {
        font-size: 12px;
        font-weight: bold;
        display: inline-flex;
        padding: 0.5em 0.7em;
        color: #ffffff;
        border-radius: 0.4em;
        background-color: #6082F1;
      }
    }
    p {
      font-size: 14px;
    }
  }

  &__art {
    margin-bottom: 32px;

    &-img {
      max-width: 100%
    }
  }

  @include respond-below(sm) {
    background-position: 5% 5%;

    &__container {
      padding-bottom: 32px;
    }

    &__title {
      font-size: 38px;
      line-height: 1.2;
      text-align: center;
      padding: 32px 0 16px 0
    }

    &__block {
      h3 {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;

        span {
          display: flex;
          width: max-content;
          margin-bottom: 14px;
        }
      }
    }

    &__art {
      display: none;
    }

    &__container {
      &-cols {
        display: block;
      }
    }
  }
}
</style>
