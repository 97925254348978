<template>
  <div class="help-us">
    <div class="help-us__wrap">
      <!-- <div class="help-us__art">
        <img alt="" class="help-us__art-img" src="@/assets/landing-questions.svg" />
      </div> -->
      <p>How important is small-talk for you?</p>
      <button onclick="window.open('https://docs.google.com/forms/d/e/1FAIpQLSd0ugE95lKRThwK4QipZaNuqO6mVOCM2uICTj77o57z1crVRg/viewform', 'blank');" value="Take Survey">Take our Survey</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'help-us',
}
</script>

<style lang="scss" scoped>
.help-us {
  padding: 64px 0;
  background: white;
  box-shadow: 0 -160px 0 0 #FFEDEE inset;

  &__wrap {
    @include container();

    position: relative;
    display: flex;
    align-items: center;
    padding: 48px 64px;
    color: #ffffff;
    border-radius: 30px;
    background: #6082F1;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  }

  p {
    font-size: 48px;
    font-weight: bold;
    flex: 1;
    margin: 0;
  }

  button {
    font-size: 16px;
    font-weight: bold;
    display: block;
    width: max-content;
    height: 48px;
    padding: 0 24px;
    color: #fff;
    border: 0 none;
    border-radius: 32px;
    background: rgba(255, 255, 255, .4);
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, .2);
    }
  }

  &__art {
    position: absolute;
    right: -20px;
    bottom: 100%;
    width: 387px;
    height: auto;

    &-img {
      max-width: 100%
    }
  }

  @include respond-below(sm) {
    position: unset;
    padding: 16px 0 42px 0;

    &__art {
      display: none;
    }

    &__wrap {
      flex-direction: column;
      max-width: 90vw;
      padding: 42px;
      text-align: center;
    }

    p {
      font-size: 32px;
    }

    button {
      margin-top: 20px;
    }
  }
}
</style>
