import { createStore } from 'vuex'

export const store = createStore({
    state() {
        return {
            selected_section: null
        }
    },

    mutations: {
        changeSection(state, { section }) {
            state.selected_section = section;
        }
    }
})