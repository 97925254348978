<template>
    <div class="small-talk__item-reviewer">
        <img alt="" class="small-talk__item-reviewer-img" src="@/assets/landing-review-michael.png" />
        <div class="small-talk__item-reviewer-name">
            <p>Michael Chetta</p>
            <small>
                an industrial and organizational<br>
                psychologist
            </small>
        </div>
    </div>
</template>

<script>
export default {
    name: "Reviewer"
}
</script>