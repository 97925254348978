<template>
  <div class="right-time">
    <div class="right-time__container">
      <div class="right-time__block">
        <div class="right-time__art">
          <img alt="" class="right-time__art-img" src="@/assets/landing-right-time.svg" />
        </div>
        <div class="right-time__text">

          <h3 class="right-time__text-title">
            Now is the <br>right time!
          </h3>
          <p class="right-time__text-p">
            Virtual meetings fatigue is real, in-depth connections with our peers at work are not
            maintained, our well-being, as well as productivity, is impacted.
          </p>
          <p class="right-time__text-p _frame">
            Swifttalk is designed to maintain close relationships within virtual teams. It
            helps newcomers to integrate into those teams, injects fun into your daily routines and provides
            knowledge sharing and visible discussion throughout your enterprise.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'right-time',
  props: ["observer"],
  mounted() {
    this.observer.observe(this.$el);
  }
}
</script>

<style lang="scss" scoped>
.right-time {
  padding: 32px 0 70px 0;
  background: linear-gradient(0deg, #6082F1 65.999%, transparent 66%);

  &__container {
    @include container();
  }
  &__block {
    display: grid;
    padding: 62px 32px 140px 32px;
    border-radius: 24px;
    background: #F8D2DD;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.08);
    grid-gap: 32px;
    grid-template-columns: minmax(0, 1.28fr) minmax(0, 1fr);
  }

  &__text {
    &-title {
      font-size: 56px;
      font-weight: bold;
      margin: 0;
    }
    &-p {
      font-size: 21px;
      margin: 32px 0;
      color: #888;
      line-height: 1.5;

      &._frame {
        box-sizing: border-box;
        padding: 16px;
        margin-bottom: 0;
        border: 4px solid #FFFFFF;
        border-radius: 16px;
        background: #8CA6FC;
        color: #ffffff;
      }
    }
  }

  &__art {
    margin-top: auto;
    &-img {
      max-width: 100%;
      height: auto;
    }
  }

  @include respond-below(sm) {
    position: relative;
    padding: 0px 0 120px 0;

    &__text {
      &-title {
        font-size: 36px;
      }
      &-p {
        margin: 24px 0 16px 0;
      }
    }
    &__block {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 40px;
      padding-top: 48px;
    }

    &__art {
      position: absolute;
      right: 0;
      bottom: 0px;
      left: 0;
      top: auto;
      &-img {
        // max-width: 100%;
        // height: auto;
    }
    }
  }

}
</style>
