// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/landing-how-it-works-bg-dots.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".how-it-works[data-v-2def23c6]{background-color:#fdf2fa;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:calc(50% + 600px) 66%;background-size:176px;padding-bottom:64px}.how-it-works__block[data-v-2def23c6]{margin:0 auto;max-width:100%;box-sizing:border-box;width:1240px;padding:0 var(--ui-container--padding,16px);display:grid;grid-template-columns:minmax(0,1.4fr) minmax(0,1fr);grid-gap:32px}@media(max-width:1200px){.how-it-works__block[data-v-2def23c6]{width:100%}}.how-it-works__text-title[data-v-2def23c6]{font-size:56px;font-weight:700;line-height:64px;margin:64px 0 32px 0}.how-it-works__text-p[data-v-2def23c6]{font-size:21px;line-height:1.4em;color:#888}.how-it-works__text-icons[data-v-2def23c6]{max-width:100%;margin-top:42px;padding:21px;border-radius:18px;background:#fff;box-shadow:10px 10px 40px rgba(0,0,0,.08)}.how-it-works__art[data-v-2def23c6]{margin-top:64px}.how-it-works__art-img[data-v-2def23c6]{width:calc(100% + 124px);margin-right:-100px;margin-left:-24px}@media(max-width:1200px){.how-it-works[data-v-2def23c6]{padding-bottom:0}.how-it-works__block[data-v-2def23c6]{display:flex;flex-direction:column-reverse}.how-it-works__text-title[data-v-2def23c6]{font-size:36px;font-weight:700;line-height:64px;margin:24px 0 0 0;text-align:center}.how-it-works__text-p[data-v-2def23c6]{font-size:21px;font-weight:400;line-height:32px}.how-it-works__text-icons[data-v-2def23c6]{padding:21px;position:unset;display:block;width:auto;max-width:100%;margin:0 auto;margin-top:42px}.how-it-works__art[data-v-2def23c6]{margin-top:0;padding-bottom:32px}.how-it-works__art-img[data-v-2def23c6]{max-width:calc(100vw - var(--ui-container--padding)*2);margin:0}}", ""]);
// Exports
module.exports = exports;
