<template>
  <div class="pay-for-time">
    <div class="pay-for-time__container">
      <h1 class="pay-for-time__title">
        Pay only for the time you spend
      </h1>
      <div class="pay-for-time__container-cols">
        <div class="pay-for-time__container-cols-col">
          <div class="pay-for-time__art">
            <img alt="" class="pay-for-time__art-img" src="@/assets/landing-pay-for-time.svg" />
          </div>
        </div>
        <div class="pay-for-time__container-col">
          <p>We understand that the adoption of any technology varies between enterprises. So, to
            make that easier, we offer a simple consumption-based pricing model.</p>
          <p>There’s no cost per
            user per month, you only pay for the minutes your employees spend using the product.
            There’s no tiers and no feature limitations… it’s as simple as that!</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pay-for-time',
  props: ["observer"],
  mounted() {
    this.observer.observe(this.$el);
  }
}
</script>

<style lang="scss" scoped>
.pay-for-time {
  padding-bottom: 64px;
  background-color: #FDF2FA;

  &__title {
    font-size: 56px;
    font-weight: bold;
    line-height: 64px;
    margin: 0;
    padding: 64px 0 32px 0;
    text-align: center;
  }

  &__container {
    @include container();

    &-cols {
      display: grid;
      align-items: center;
      grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
      grid-gap: 42px;
    }

    &-col {
      p {
        font-size: 16px;
        line-height: 32px;
        padding: 0 0 0 224px;
        text-align: center;
        color: #666;

        &:last-child {
          font-weight: 800;
          color: #6082F1;
        }
      }
    }
  }

  &__art {
    &-img {
      max-width: 150%
    }
  }

  @include respond-below(sm) {
    padding: 0;
    background-color: white;

    &__container {
      padding-bottom: 32px;
    }

    &__title {
      padding: 16px 0 16px 0;
      font-size: 38px;
      line-height: 1.2;
    }

    &__container {
      &-cols {
        display: block;
      }

      &-col {
        p {
          font-size: 16px;
          line-height: 32px;
          padding: 0 24px;
          text-align: center;
        }
      }
    }

    &__art {
      &-img {
        position: unset;
        display: block;
        width: auto;
        max-width: 80%;
        margin: 0 auto;
        margin-top: 24px;
      }
    }
  }
}
</style>
